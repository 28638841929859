import React from "react"
import { Layout } from "../components/Layout"
import { Section } from "../components/Section"
import { Container } from "../components/Container"

export default () => (
  <Layout seo={{
    title: "Oops",
    path: "/404",
    description: "Page not found"
  }}>
    <Section id="404">
      <Container width="tight">
        <div className="centered">
          <h2>Page is Missing</h2>
          <p>
            It looks like you were sent to a page which does not exist.
          </p>
        </div>
      </Container>
    </Section>
  </Layout>
);
